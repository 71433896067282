@keyframes scale {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}

@keyframes openDialog {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@mainColor: #FD3F00;

.containerWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    animation: openDialog .3s forwards;

    &.vistorProductDetail {
        background: url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/tuiguang-shangpinxiangqingbg@1x.jpg') no-repeat;
        background-size: 100% 100%;
    }
}

.container {
    border-radius: 36px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 844px;
    height: 730px;
    background: url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/tuiguang-shangxiang-ercilanjieyouhua@3x.png') no-repeat;
    background-size: 100% 100%;
    animation: scale 0.3s forwards;

    .leftImgBox {
        width: 548px;
        height: 570px;
        position: relative;

        .leftImgBg {
            width: 548px;
            height: 570px;
            border-top-left-radius: 36px;
            border-bottom-left-radius: 36px;
        }

        .leftImgItem {
            position: absolute;
            left: -50px;
            top: -16px;
            width: 137px;
            height: 240px;
        }
    }

    .rightPayBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.9);
        border: 2px solid #FFFFFF;
        backdrop-filter: blur(30px);
        width: 252px;
        height: 538px;
        position: absolute;
        right: 16px;
        bottom: 40px;

        .priceBox {
            display: flex;
            align-items: baseline;
            color: @mainColor;
            font-weight: 500;
            font-family: klein Font;
            position: relative;
            margin-top: 24px;

            .price {
                font-size: 50px;
            }

            .unit {
                font-size: 24px;
            }

            .topTip {
                font-family: DingTalk JinBuTi;
                font-size: 14px;
                color: #FD3F00;
                border-radius: 10px 0px;
                background: #FFF0EB;
                width: 64px;
                height: 19px;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -100%);
                text-align: center;
                line-height: 19px;
            }
        }

        .tipBox {
            font-family: DingTalk JinBuTi;
            width: 100px;
            height: 26px;
            border-radius: 15.75px 0px;
            background: #FFF0EB;
            color: @mainColor;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin-bottom: 20px;
        }

        .tipHint {
            font-family: Source Han Sans;
            font-size: 14px;
            line-height: 22px;
            color: #B6BABF;
            margin-bottom: 18px;
        }

        .autoHint {
            font-family: Source Han Sans;
            font-size: 14px;
            color: #B6BABF;
            line-height: 22px;
            margin-bottom: 9px;
        }

        .qrCodeBox {
            width: 156px;
            height: 156px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            box-sizing: border-box;
            border: 3px solid transparent;
            border-radius: 10px;
            background-origin: border-box;
            background-clip: content-box, border-box;
            position: relative;
            z-index: 2;
            overflow: hidden;
            background-image: linear-gradient(#FFFFFF, #FFFFFF), conic-gradient(from 180deg at 50% 50%, rgba(255, 207, 124, 0.3) -11deg, rgba(255, 72, 70, 0.3) 93deg, rgba(255, 207, 124, 0.3) 349deg, rgba(255, 72, 70, 0.3) 453deg);

            .qrLoading {
                color: #722AFF !important;
                width: 136px;
                height: 136px;
            }

            .qrCode {
                width: 136px;
                height: 136px;
            }

            :global {
                .ant-spin-nested-loading .ant-spin-blur {
                    opacity: 0.7;

                    &::after {
                        opacity: 0.7;

                    }
                }

                .ant-spin-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .codeMask {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(255, 255, 255, 0.95);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                line-height: 24px;
                color: #3D3D3D;
                cursor: pointer;

                .update {
                    width: 22px;
                    height: 22px;
                    margin-bottom: 12px;
                }
            }
        }

        .vistorProductDetailQrCodeBoxWrapper {
            position: relative;
            margin-bottom: 34px;

            &::after {
                display: block;
                content: '充值后请绑定手机号';
                width: 156px;
                height: 49px;
                border-radius: 0px 0px 16px 16px;
                background: #FFF0EB;
                font-family: Source Han Sans;
                font-size: 14px;
                color: #FD3F00;
                bottom: -31px;
                position: absolute;
                box-sizing: border-box;
                padding-top: 22.5px;
                text-align: center;
            }
        }

        .hintBox {
            display: flex;
            font-family: PingFang SC;
            font-size: 14px;
            color: #1E2226;
            margin-top: 13px;
            align-items: center;

            .payIcon {
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }
        }

        .clauseBox {
            font-family: Source Han Sans;
            font-size: 14px;
            color: #1E2226;
            text-align: center;
            margin-top: 39px;
            line-height: 24px;

            .clause {
                color: @mainColor;
                cursor: pointer;
            }
        }
    }

    .paySecondIntercept {
        margin-top: 40px;
        width: 220px;
        height: 48px;
        border-radius: 10px;
        cursor: pointer;
    }

    .tip {
        margin-top: 10px;
        opacity: 0.4;
        font-size: 14px;
        line-height: 24px;
        font-variation-settings: 'opsz' auto;
        color: #a61919;
    }
}

.closeBtn {
    position: absolute;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, 0.3);
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    color: #ffffff;
    cursor: pointer;
    top: 96px;
    right: -44px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }
}

.marketingPoster {
    .container {
        background: url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/haibao-ercilanjieyouhua@3x.png') no-repeat;
        background-size: 100% 100%;
    }
}