.content{
    width: 652px;
    // height: 430px;
    flex: 1;
    border-radius: 0px 0px 20px 20px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px;
    box-sizing: border-box;
    .promptTxt {
        margin-top: 16px;
        font-family: Source Han Sans;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF2626;
        line-height: 20px;
        .icon {
            font-size: 16px;
            margin-right: 3px;
            line-height: 1;
        }
    }
    .goods{
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        gap: 12px;
    }
    .item{
        cursor: pointer;
        flex: 1;
        height: 160px;
        border-radius: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background: #FFEEE8;
        border: 1px solid transparent;
        .newTip{
            position: absolute;
            left: 0;
            top: 0;
            // width: 76px;
            height: 20px;
            border-radius: 11px 0px 11px 0px;
            padding: 1px 8px;
            box-sizing: border-box;
            background: linear-gradient(114deg, #FF3DA8 2%, #FF5FEA 80%);
            font-family: Weimob Increase;
            font-size: 12px;
            line-height: 18px;
            color: #FFFFFF;
        }
        .goodName{
            margin-top: 24px;
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0px;
            color: #1E2226;
        }

        .originPrice{
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0px;
            text-decoration: line-through;
            color: #B6BABF;
                
        }
        .bottom{
            position: absolute;
            left: 3px;
            right: 3px;
            bottom: 3px;
            height:31px;
            line-height: 31px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 10px;
            font-size: 14px;
            color: #1E2226;
        }
        &:hover {
            // outline: 1px solid #D53DFF;
            background-image: linear-gradient(#FFEEE8, #FFEEE8), linear-gradient(153deg, #FB3DAD -1%, #FF873C 84%);
            background-origin: border-box;
            background-clip: content-box, border-box;
        }
        &.notWithPrompt {
            height: 196px;
            .goodName {
                margin-top: 42px;
            }
        }
    }
    .active{
        box-shadow: 0px 0px 30px 0px rgba(212, 124, 41, 0.3);
        background-image: linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(153deg, #FB3DAD -1%, #FF873C 84%) !important;
        background-origin: border-box;
        background-clip: content-box, border-box;
        
        .bottom{
            // left: 0;
            background: linear-gradient(111deg, #FF6D3D 3%, #FF4A02 80%);
            // bottom: 0;
            // right: 0;
            // border-radius: 0 0 11px 11px;
            color: #FFFFFF;
            font-weight: 600;
            .bottomText{
                background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(93deg, #1E2226 -2%, #FF5E3E -2%, #FF47CE 107%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        .price{
            color: #FD3F00;
        }

    }
    .activityItem{
        .newTip{
            background: linear-gradient(121deg, #FE581A 0%, #FE7B3F 83%), linear-gradient(121deg, #FE581A 0%, #FE7B3F 83%);
        }
        .bottom{
            font-weight: 600;
        }
        .bottomText{
            background: #FD3F00;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    .des{
        padding: 0 16px;
        font-size: 14px;
        line-height: 22px;
        color: #8A9099;
        display: flex;
        align-items: center;
        .tipIcon{
            font-size: 14px !important;
            color: #8A9099;
            margin-left: 4px;
            cursor: pointer;
        }
    }
    .paycontainer{
        display: flex;
    }
    .payCode{
        width: 620px;
        height: 184px;
        border-radius: 12px;
        background: #F5F7FA;
        margin-top: 16px;
        margin-left: 16px;
        padding: 16px;
        box-sizing: border-box;
        border-radius: 10px;
        .payWay{
            width: 102px;
            height: 38px;
            box-sizing: border-box;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 14px;
            line-height: 22px;
            color: #1E2226;
            &:hover{
                background: #E9ECF0;
            }
                
        }
        .activePay{
            color: #844A20;
            background: #EE8351;
            font-weight: 600;
            color: #FFFFFF;
            &:hover{
                background: #EE8351;
            }

        }
        .code{
            width: 152px;
            height: 152px;
            box-sizing: border-box;
            border: 2px solid transparent;
            border-radius: 10px;
            background-image: linear-gradient(#FFFFFF, #FFFFFF),
            linear-gradient(135deg, #E24E4C -8%, #FF7547 103%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 12px;
            margin-right: 16px;
            img{
                width: 132px;
                height: 132px;
            }
            :global{
                .ant-spin-nested-loading .ant-spin-blur{
                    opacity: 0.7;
                    &::after{
                        opacity: 0.7;
        
                    }
                }
                .ant-spin-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
        
            }
                  
        }
        .paydes{
            display: flex;
            align-items: center;
            margin-top: 13px;
            font-size: 14px;
            line-height: 22px;
            color: #333333;
            img{
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }
        }
        .xy{
            margin-top: 31px;
            font-size: 14px;
            line-height: 24px;
            color: #1E2226 ;
            span{
                color: #FD3F00;
                cursor: pointer;
            }
            
            
        }
    }
    .goToMember{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tip{
            font-size: 14px;
            line-height: 22px;
            font-weight: 400; 
            color: #1E2226;
         span{
            font-weight: 600;
         }
            
        }
        .action{
            margin-top: 17px;
            font-size: 14px;
            line-height: 22px;
            color: #722AFF;
            cursor: pointer;
        }
    }
    .price{
        position: relative;
        left: -7px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        font-family: klein Font;
        font-size: 36px;
        font-weight: 500;
        line-height: 36px;
        color: #1E2226;
        .tiyan{
            font-family: PingFang SC;
            font-size: 12px;
            line-height: 20px;
            color: #1E2226;
            font-weight: 400;
            margin-top: 9px;
        }
        .firstMonth{
            position: absolute;
            width: 28px;
            height: 14px;
            border-radius: 4px 4px 4px 0px;
            padding: 0px 4px;
            box-sizing: border-box;
            background: #EC7435;
            font-family: Inter;
            font-size: 10px;
            line-height: 14px;
            color: #FFFFFF;
            right: -27px;
            top: -9px;

        }
        .priceSign{
            width: 14px;
            height: 50%;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: right;
            letter-spacing: 0px;
            text-shadow: 0px 1px 14px rgba(79, 122, 253, 0.0001);
            margin-top: 4px;
        }

    }
    .payInfo{
        .price{
            color: #FD3F00;
            .priceSign{
                margin-top: 10px;

            }
        }
    }
        
}

.payResult{
    display: flex;
    flex-direction: column;
    padding-top: 110px;
    align-items: center;
    color: #1E2226;

    img{
        width: 88px;
        height: 88px;
    }
    .result{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 16px;
    }
    .des{
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        span{
            font-weight: 600;
        }
        
    }
}
.imgContainer{
    position: relative;
    height: 132px;
    width: 132px;
    .codeMask{
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.95);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        color: #3D3D3D;
        cursor: pointer;
        .update{
            width: 22px;
            height: 22px;
            margin-bottom: 12px;
        }
    }
}
.qrLoading{
    color: #722AFF !important;
}
.cutDown{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .bottomText{
        line-height: 18px;
        font-weight: 400 !important;
    }
    :global{
        .ant-statistic{
            width: 84px !important;
            display: flex;
            align-items: center;
            
        }
        .ant-statistic-content{
            height: 100%;
            display: flex;
            align-items: center
        }
        .ant-statistic-content-value{
            font-family: klein Font;
            color: #FD3F00;
            letter-spacing: 1px;
            font-size: 16px !important;
           font-weight: 600 !important;
           line-height: 18px;
        }

    }

}
.active{
    .cutDown{
        :global{
            .ant-statistic-content-value{
                // color: #FFFFFF !important;
                background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(93deg, #1E2226 -2%, #FF5E3E -2%, #FF47CE 107%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }

    }
}
.tipPopContainer{
    .popTip{
        width: 436px;
        font-size: 14px;
        line-height: 22px;
        color: #626973;

    }
    :global{
        .ant-popover-inner{
            padding: 8px 12px;
        }
        .ant-popover-arrow{
            margin-right: -12px;
        }
        .ant-popover-content{
            margin-right: -12px;
        }

    }
}

.ac {
    display: flex;
    align-items: center;
}

.failPart, .successPart {
    display: flex;
    align-items: center;
    
    .icon {
        font-size: 24px;
        color: #FF5050;
        margin-right: 6px;
        line-height: 1;
    }
    .result {
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0px;
        color: #1E2226; 
        margin: 0;
    }
    .desc {
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #1E2226;
        margin-top: 8px;
    }
}
.successPart {
    .icon {
        color: #5FD562;
    }
    .des1 {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        margin-top: 8px;
        line-height: 22px;
        span {
            font-weight: 600;
        }
    }
    .des2 {
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #8A9099;
        margin-top: 4px;
    }
}