@import url(@/global.less);
.couponMask {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.6);

  backdrop-filter: blur(10px);
  display: flex;

  .coupon {
    margin: auto;
    width: 305px;
    height: 360px;
    position: relative;
    background-image: url(https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/bg_xianshihongbao.png);
    background-size: 100% 100%;

    p {
      color: #fff;
    }

    .close {
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 32px;
      background: rgba(255, 255, 255, 0.3);
      position: absolute;
      color: #fff;
      right: -36px;
      top: -32px;
      cursor: pointer;
      &:hover {
        background-color: #fff9;
      }
    }
  }

  .btn {
    width: 180px;
    display: block;
    height: 46px;
    background: linear-gradient(180deg, #FFEB81 0%, #FFFCCC 100%);
    border-radius: 40px;
    margin: 22px auto 0;
    .font(18px, 600, #E11D2F);
    &:hover {
      background: linear-gradient(180deg, #F6DF65 2%, #F4EF95 100%);
    }
  }

  .price {
    line-height: 1;
    margin-top: 150px;
    font-family: wemo;
    text-align: center;

    span:first-child {
      .font(28px, 500, #fff);
    }

    span:last-child {
      .font(90px, 500, #fff);
    }
  }

  .text {
    line-height: 1;
    .font(14px, 400, #fff);
    text-align: center;
  }
}
.couponBox {
    width: 439px;
    height: 542px;
    position: relative;
    margin: auto;
    background: url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/xinyonghuzhuanxiang-danchuang@3x.png') no-repeat;
    background-size: contain;
    cursor: pointer;
    .close {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 32px;
        background: rgba(255, 255, 255, 0.3);
        position: absolute;
        color: #fff;
        right: -16px;
        top: 45px;
        cursor: pointer;
        &:hover {
            background-color: #fff9;
        }
    }
}