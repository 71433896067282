@import url(@/global.less);

.wrapper {
  :global {
    .ant-modal-confirm-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #1e2226;
      .modalTitle {
        display: flex;
        align-items: center;
        .font(20px, 600, #1e2226);
      }
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
    }
    .ant-modal-confirm-btns {
      margin-top: 44px;
    }
    .ant-modal-confirm-content {
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      color: #8a9099;
      text-align: center;
    }
    .ant-modal-content {
      width: 440px;
      padding: 40px 24px 24px 24px;
    }
    .ant-modal-confirm-paragraph {
      display: flex;
      align-items: center;
    }
    .ant-modal-confirm-btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-modal-confirm-btns {
      .ant-btn-default {
        width: 144px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: #f5f7fa;
        border: none;
        background-color: #f5f7fa;
        color: #1e2226;
        font-size: 16px;

        &:hover {
          background-color: #f0f2f5;
          color: #1e2226;
        }

        &:active {
          background-color: #e9ecf0;
          color: #1e2226;
        }

        &:disabled {
          background-color: #f0f2f5;
          color: #b6babf;
        }
      }
      .ant-btn + .ant-btn {
        margin-left: 12px;
        width: 144px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background: #722aff;
        border: none;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #ffffff;
        &:hover {
          background-color: #8066ff;
        }

        &:active {
          background-color: #560ee6;
        }

        &:disabled {
          background-color: #bfb2ff;
        }
      }
    }
  }
}

.tipWrap {
  position: relative;

  .tip {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 18px;
    text-align: center;
    padding: 0 9px;
    border-radius: 12px 12px 12px 0px;
    background: linear-gradient(112deg, #ff3da8 3%, #ff5fea 80%);
    color: #ffffff;
    font-size: 10px;
    font-family: Weimob Increase;
    transform: translate3d(calc(100% - 6px), -100%, 0);
    font-weight: normal;
  }
}
