.modalContainer {
  opacity: 1;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0 !important;
  margin-inline-start: 0 !important;
  gap: 12px;

  :global {
    .ant-btn-default{
      width: 144px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      background-color: #F5F7FA;
      color: #1E2226;
      font-size: 16px;

      &:hover {
        background-color: #F0F2F5 !important;
        color: #1E2226 !important;
      }

      &:active {
        background-color: #E9ECF0 !important;
        color: #1E2226 !important;
      }

      &:disabled {
        background-color: #F0F2F5 !important;
        color: #B6BABF !important;
      }
    }

    .ant-btn-primary {
      width: 144px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background: #722AFF;
      border: none;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #FFFFFF;
      &:hover {
        background-color: #8066FF !important;
      }

      &:active {
        background-color: #560EE6 !important;
      }

      &:disabled {
        background-color: #BFB2FF !important;
      }
    }
  }
}

.btn {
  min-width: 112px;
  height: 40px;
}