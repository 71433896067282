@import url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/490/1.72.1735029235268/icon.css');


@theme-blue: #722aff;
@heaviest: #1e2226;

.font(@fontSize, @fontWeight, @fontColor) {
  font-size: @fontSize;
  font-weight: @fontWeight;
  color: @fontColor;
}

@font-face {
  font-family: Weimob Increase;
  src: url('https://c.weimobwmc.com/ol-6LF7L/e0cf012f279b4e7aa1c858f4fe9baac1.ttf');
}

@font-face {
  font-family: klein Font;
  src: url('https://c.weimobwmc.com/ol-6LF7L/1f5820c8f33848f08b97542b5da6b1f2.ttf');
}

@font-face {
  font-family: Alimama ShuHeiTi;
  src: url('https://c.weimobwmc.com/ol-6LF7L/e15ac94653514692b20591ef098482cb.ttf');
}

@font-face {
  font-family: WEMO Num;
  src: url('https://c.weimobwmc.com/ol-6LF7L/f8266f9c8a0a448da049d9df81faa03e.ttf');
}

@font-face{
    font-family: DingTalk JinBuTi;
    src: url(https://c.weimobwmc.com/ol-6LF7L/baec9e4b11f14d328e14d65c15030283.woff);
}

@font-face{
  font-family: Source Han Sans;
  src: url(https://c.weimobwmc.com/ol-6LF7L/e337a6139ca94dc2b6ae9c2697b89f75.woff);
}
@font-face{
  font-family: Gilroy;
  src: url(https://c.weimobwmc.com/ol-6LF7L/a15283da0f3d4434bb9c71c800537043.woff);
}

html {
  height: 100%;
  width: 100%;
}

.ellipsis(@line) {
  -webkit-line-clamp: @line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.circular(@diameter) {
  width: @diameter;
  height: @diameter;
  border-radius: @diameter;
}

.hideScrollBar () {
  &::-webkit-scrollbar {
    width: 0;
  }
}

.ant-input-outlined,.ant-input-number-affix-wrapper {
  background-color: #f5f7fa;
  border: 1px solid transparent;
  color: #1e2226;
  box-shadow: none !important;
  border-radius: 4px;

  input {
    cursor: pointer;
  }

  &:focus-within {
    border-color: #560ee5 !important;
    background-color: #fff !important;
  }

  &:hover {
    background: #F0F2F5;
    border-color: transparent;
  }
}

.ant-input-suffix {
  color: #b6babf;
}

.ant-select-selection-item {
  line-height: 36px !important;
}

.ant-select {
  height: 36px;
}

.ant-select-selector {
  border-radius: 4px !important;
  box-shadow: none !important;
  border-color: transparent !important;
  background-color: #f5f7fa !important;
}

.ant-select-open {
  .ant-select-selector {
    border-color: #560ee5 !important;
    background-color: #fff !important;
  }
}

.ant-input-textarea-show-count {
  padding-bottom: 30px !important;
}

.transparentGrid(@gridSize) {
  background: linear-gradient(45deg, #dedcdc 25%, transparent 0, transparent 75%, #dedcdc 0),
  linear-gradient(45deg, #dedcdc 25%, transparent 0, transparent 75%, #dedcdc 0);
  background-position: 0px 0px, calc(@gridSize + 0px) calc(@gridSize + 0px);
  background-size: calc(@gridSize * 2) calc(@gridSize * 2);
}

.ant-spin-nested-loading {
  width: 100%;
  height: 100%;

  .ant-spin-container {
    width: 100%;
    height: 100%;
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    border-radius: 2px;
    border: none !important;
    background-color: #f0f2f5;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background: @theme-blue !important;
    border: none !important;
  }
}

.ant-segmented-item-label {
  height: 34px;
  line-height: 34px !important;
  padding: 0 20px !important;
}

.ant-segmented-thumb {
  border-radius: 50px !important;
}

.ant-segmented {
  border-radius: 50px !important;
}

.ant-segmented-item {
  border-radius: 50px !important;
}

.ant-segmented-item-selected {
  .ant-segmented-item-label {
    font-weight: 600;
  }
}

@keyframes scan {
  0%, 100% {
    top: -400px;
  }
  50% {
    top: 100%;
  }
}

@keyframes flip {
  0%, 49.99% {
    transform: scaleY(1);
  }
  50%, 100% {
    transform: scaleY(-1);
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", system-ui;
}

.createTourWrapper{
  width: auto;
  font-family: PingFang SC;
  color: #1E2226;
  .ant-tour-content{
    width: 332px;
  }
  .ant-tour-close{
    display: none !important;
  }
  .ant-tour-header{
    padding: 20px 24px 8px !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

  }
  .ant-tour-description{
    padding: 0 24px !important;
  }
  .ant-tour-footer{
    padding: 24px 24px 20px 24px !important;
  }
  .ant-tour-prev-btn{
    display: none;
  }
  .ant-tour-next-btn{
    background: #722AFF;
    padding: 5px 16px;
    border-radius: 28px;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    height: auto;
    &:hover{
      background: #8066FF !important;
    }
    &:active{
      background: #560EE6 !important;
    }
  }
}
.ant-message{
  z-index: 10000 !important;
}
.tryTourWrapper{
  .ant-tour-prev-btn{
    display:  inline-block;;
    border: none;
    padding: 5px 16px;
    border-radius: 28px;
    font-size: 14px;
    line-height: 22px;
    color: #8A9099;
    height: auto;
    box-shadow: none;
    &:hover{
      background: #F5F7FA !important;
      color: #1E2226 !important;
    }
    &:active{
      background: #F0F2F5 !important;
      color: #1E2226 !important;
    }


  }
}
.tourMask{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
}
.containerRelative{
  position: relative;
}
// .swiper-pagination-bullet {
//   width: 40px !important;
//   height: 4px !important;
//   display: inline-block !important;
//   background: #fff !important;
//   opacity: .2 !important;
//   margin: 0 5px !important;
//   border-radius: 20px !important;
// }

// .swiper-pagination-bullet-active {
//   opacity: 1 !important;
//   background: #ffffff !important;
// }
