.customerServiceBox {
    width: 588px;
    height: 128px;
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 12px;
    background: #F5F7FA;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    .bgImg {
        width: 180px;
        height: 178px;
    }

    .leftPart {
        display: flex;
        align-items: center;

        .qrCode {
            width: 88px;
            height: 88px;
        }

        .desc {
            font-family: PingFang SC;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0px;
            color: #1E2226;
            margin-left: 12px;
        }
    }
}