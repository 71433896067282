html:first-child {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html:first-child,
html body,
body #root {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 1440px;
  position: relative;
  overflow-y: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", system-ui;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body p {
  margin: 0;
}
body ul,
body ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
body a {
  cursor: pointer;
  user-select: none;
}
body button {
  cursor: pointer;
  outline: none;
  user-select: none;
  border: none;
  line-height: 1;
}
body input {
  outline: none;
  border-width: 1px;
}
@font-face {
  font-family: wemo;
  src: url(../../assets/fonts/kleinFontVF.ttf);
}
body hr {
  border: none;
  height: 1px;
  background-color: #000;
}
body img {
  user-select: none;
  display: block;
}
body ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
body ::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
