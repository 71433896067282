@import url(@/global.less);

@grey: #626973;
@deep: #1e2226;

.screenMask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    z-index: 500;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
}
.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    .close {
        .circular(32px);
        line-height: 32px;
        color: #fff;
        text-align: center;
        position: absolute;
        background: rgba(255, 255, 255, 0.3);
        right: -32px;
        top: -32px;
        cursor: pointer;
        &:hover {
          background-color: #fff9;
        }
    }
    .contentBox {
        .title {
            font-family: PingFang SC;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            color: #1E2226;
            text-align: center;
        }
        .hint {
            margin-bottom: 20px;
            font-family: PingFang SC;
            font-size: 16px;
            color: #8A9099;
            text-align: center;
            line-height: 24px;
            margin-top: 4px;
        }
        .inpItem {
            width: 364px;
            height: 56px;
            background: #F5F7FA;
            border-radius: 56px;
            padding: 16px 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            .inp {
                flex: 1;
                border: none;
                background: none;
                font-size: 16px;
                color: @deep;
                &::placeholder {
                    color: #B6BABF; 
                }
            }
            .code {
                font-family: PingFang SC;
                font-size: 16px;
                color: #722AFF;
                margin-left: 16px;
                cursor: pointer;
                &:hover {
                    color: #8066FF;
                }
                &:active {
                    color: #560EE6;
                }
            }
            .count {
                color: #722AFF;
            }
        }
        .bindBtn {
            width: 365px;
            height: 56px;
            font-family: PingFang SC;
            font-weight: 600;
            font-size: 16px;
        }
    }
}
.zoneDrop {
    .areaNum {
      font-family: klein Font;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    :global {
      .ant-dropdown-menu {
        max-height: 220px;
        overflow-y: auto;
        width: 182px;
        padding: 8px 0;
        margin-top: 18px;
      }
      .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
        background: #f0f2f5;
      }
    }
}
.areaNo {
    position: relative;
    font-family: WEMONum;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 16px;
    margin-right: 16px;
    .font(18px, 500, @deep);
    &::after {
      position: absolute;
      content: '';
      width: 1px;
      height: 16px;
      background-color: #d8d8d8;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    img {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
}
.openDialog {
    animation: openDialog .3s forwards;
    .telBox {
        width: 445px;
        height: 358px;
        border-radius: 20px;
        opacity: 1;
        background: #FFFFFF;
        padding: 40px;
        box-sizing: border-box;
        animation: scale .3s forwards;
    }
}
.closeDialog {
    animation: openDialog 0.3s reverse ease-in forwards;
    .telBox {
      animation: scale 0.3s reverse ease-in forwards;
    }
}

@keyframes openDialog {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes scale {
    from {
      transform: scale(.8);
    }
  
    to {
      transform: scale(1);
    }
}