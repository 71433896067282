.btn {
  transition: all .1s;
  border-radius: 100px;
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px 24px;

  &:disabled {
    cursor: not-allowed;
  }

}


.btn[data-type="primary"] {
  background-color: #722AFF;
  color: #fff;

  &:hover {
    background-color: #8066FF;
  }

  &:active {
    background-color: #560EE5;
  }

  &:disabled {
    background-color: #BFB2FF;
  }
}

.btn[data-type="white"] {
  background-color: #fff;
  color: #1E2226;
  backdrop-filter: blur(20px);

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:disabled {
    background-color: #fff;
    color: #B6BABF;
  }
}

.btn[data-type="transparent"] {
  background-color: rgba(255, 255, 255, 0.15);
  border-color:  rgba(255, 255, 255, 0.4);
  color: #fff;

  &:hover {
    color:rgba(255, 255, 255, 0.8)
  }

  &:active {
    color:rgba(255, 255, 255, 0.5)

  }

  &:disabled {
    color:rgba(255, 255, 255, 0.6)
  }
}

.btn[data-type="grey"] {
  background-color: #F5F7FA;
  color: #1E2226;

  &:hover {
    background-color: #F0F2F5;
  }

  &:active {
    background-color: #E9ECF0;
    color: #1E2226;
  }

  &:disabled {
    background-color: #F0F2F5;
    color: #B6BABF;
  }
}

.btn[data-type="line"] {
  background-color: #F5F2FF;
  border-color: #722AFF;
  color: #722AFF;

  &:hover {
    border-color: #8066FF;
    color: #8066FF;
  }

  &:active {
    border-color: #560EE6;
    color: #560EE6;
  }

    &:disabled {
    color: #BFB2FF;
    border-color: #BFB2FF;
  }
}

.btn[data-type="light"] {
  background-color: #F5F2FF;
  color: #722AFF;

  &:hover {
    background-color: #722AFF;
    color: #fff;
  }

  &:active {
    background-color: #560EE6;
    color: #fff;
  }

  &:disabled {
    color: #BFB2FF;
    background-color: #F5F2FF;
  }
}

.btn[data-type="none"] {
  color: #1E2226;
  background-color: transparent;

  &:hover {
    color: #722AFF;
  }

  &:active {
    color: #560EE6;
  }

  &:disabled {
    color: #B6BABF;
  }
}

.btn[data-type="link"] {
  color: #722AFF;
  padding: 0;

  &:hover {
    color: #8066FF;
  }
}

.btn[data-type="line-white"] {
  color: #722AFF;
  background-color: #FFFFFF;
  border-color: #722AFF;

  &:hover {
    color: #8066FF;
    border-color: #8066FF;
  }

  &:active {
    color: #560EE6;
    border-color: #560EE6;

  }

  &:disabled {
    color: #BFB2FF;
    border-color: #BFB2FF;
  }
}

.btn[data-type="transparent-white"] {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #FFFFFF;
  backdrop-filter: blur(10px);
  color: #FFFFFF;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    color:rgba(255, 255, 255, 0.6)
    
  }
}
.btn{
  &:disabled {
    cursor: not-allowed !important;
  }

}  
